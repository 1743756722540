<template>
  <div class="app-container table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.access_rights") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <export-excel
          v-can="'permissions.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Права доступа"
          name="Права доступа.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'permissions'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table
      class="table table-bordered vld-parent table-hover"
      v-loading="loadingData"
    >
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.parent_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.slug"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.parent_id.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.parent_id.title"
              size="mini"
              v-model="filterForm.parent_id"
            >
              <el-option
                v-for="item in parent_permissions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.slug.show">
            <el-input
              size="mini"
              v-model="filterForm.slug"
              :placeholder="columns.slug.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="permission in list"
          :key="permission.id"
          class="cursor-pointer"
        >
          <td v-if="columns.id.show">{{ permission.id }}</td>
          <td v-if="columns.name.show">{{ permission.name | text_trunc }}</td>
          <td v-if="columns.parent_id.show">
            {{ permission.parent ? permission.parent.name : "" }}
          </td>
          <td v-if="columns.slug.show">{{ permission.slug }}</td>
          <td v-if="columns.created_at.show">
            {{ permission.created_at }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ permission.updated_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="permission"
              name="permissions"
              :actions="actions"
              @edit="edit"
              @delete="destroyModel"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-pagination
      @current-change="handleCurrentChange"
      class="mt-3 mypagination"
      background
      layout="prev, pager, next"
      :page-size="pagination.per_page"
      :total="pagination.total"
    >
    </el-pagination>
    <!-- Updating Role model  -->
    <el-drawer
      class="popups_title"
      :visible.sync="drawerUpdate"
      direction="rtl"
      size="80%" :wrapperClosable="false"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
    >
      <CrmUpdate
        :reloadModel="reopenUpdate"
        @open="reopenUpdate = true"
        @c-close="closeDrawer"
      ></CrmUpdate>
    </el-drawer>
    <!-- Updating Role model  -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmUpdate from "./update";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "PermissionsList",
  components: { CrmUpdate },
  data() {
    return {
      parents: [],
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "permissions/list",
      columns: "permissions/columns",
      pagination: "permissions/pagination",
      filter: "permissions/filter",
      sort: "permissions/sort",
      parent_permissions: "permissions/parent_permissions",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();

    if (this.parent_permissions && this.parent_permissions.length === 0)
      this.loadParentPermissions();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "permissions/index",
      updateSort: "permissions/updateSort",
      updateFilter: "permissions/updateFilter",
      updateColumn: "permissions/updateColumn",
      updatePagination: "permissions/updatePagination",
      editModel: "permissions/show",
      empty: "permissions/empty",
      delete: "permissions/destroy",
      refreshData: "permissions/refreshData",
      loadParentPermissions: "permissions/parent_permissions",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
        .then((res) => {})
        .catch((err) => {});
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "parent_id":
                this.excel_fields[column.title] = "parent.name";
                break;
              default:
                this.excel_fields[column.title] = column.column;
                break;
            }
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
            this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
